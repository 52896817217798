import React from 'react';
import Scrollspy from 'react-scrollspy';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Scroll from '../components/Scroll';
import overLayPic from '../assets/images/overlay.png';
import { Link } from 'gatsby';

const sections = [
  { id: 'one', bg: require('../assets/images/pic01.jpg') },
  { id: 'two', bg: require('../assets/images/pic02.jpg') },
  { id: 'three', bg: require('../assets/images/pic03.jpg') },
];
const IndexPage = () => (
  <Layout>
    <Header />

    <section id="one" className="main special">
      <div className="container">
        <span className="image fit primary">
          <img src={sections[0].bg} alt="" />
        </span>
        <div className="content">
          <header className="major">
            <h2>Rosemary Home Services</h2>
          </header>
          <p>
          Rosemary Homes Services design condos, houses, offices, and business retail – be it permanent or temporary. Our solutions include finding the home, design and decorate, moving services, deep cleaning, light construction, and furniture rental.
          </p>
        </div>
        <Scroll type="id" element="two">
          <a href="#two" className="goto-next ">
            Next
          </a>
        </Scroll>
      </div>
    </section>

    <section id="two" className="main special">
      <div className="container">
        <span className="image fit primary">
          <img src={sections[1].bg} alt="" />
        </span>
        <div className="content">
          <header className="major">
            <h2>Home Staging</h2>
          </header>
          <p>
          Rosemary Home Services is designed to assist realtors, homeowners, investors and anyone else who is interested in capitalizing on real estate. Our team of professional design specialists are knowledgeable individuals who are able to customize furniture and decor rentals. 
          </p>
          {/*
          <ul className="icons-grid">
            <li>
              <span className="icon major fa-camera-retro" />
              <h3>Photography</h3>
            </li>
            <li>
              <span className="icon major fa-pencil" />
              <h3>Blogging</h3>
            </li>
            <li>
              <span className="icon major fa-code" />
              <h3>Web Development</h3>
            </li>
            <li>
              <span className="icon major fa-coffee" />
              <h3>Sipping Coffee</h3>
            </li>
          </ul>
          */}
        </div>
        <Scroll type="id" element="three">
          <a href="#three" className="goto-next ">
            Next
          </a>
        </Scroll>
      </div>
    </section>

    <section id="three" className="main special">
      <div className="container">
        <span className="image fit primary">
          <img src={sections[2].bg} alt="" />
        </span>
        <div className="content">
          <header className="major">
            <h2>Relocation Services</h2>
          </header>
          <p>
          We’re here to make your home just right for you. Whether you need to downsize, move, or align your current home with your changing needs, our experienced guidance gets you your temporary or permanent home.
          </p>
        </div>
        <Scroll type="id" element="footer">
          <a href="#footer" className="goto-next ">
            Next
          </a>
        </Scroll>
      </div>
    </section>

    <section id="footer">
      <div className="container">
        <header className="major">
          <h3> Inquiries info@rosemaryhomeservices.com</h3>
        </header>
        <form name="contact" method="post" action="/" data-netlify="true" data-netlify-honeypot="bot-field" hidden> 
          <div className="row gtr-uniform">
            <div className="col-6 col-12-xsmall">
              <input type="text" name="name" placeholder="Name" />
            </div>
            <div className="col-6 col-12-xsmall">
              <input type="email" name="email" placeholder="Email" />
            </div>
            <div className="col-12">
              <textarea
                name="message"
                
                placeholder="Message"
                rows="4"
              />
            </div>
            <div className="col-12">
              <ul className="actions special">
                <li>
                  <input
                    type="submit"
                    value="Send Message"
                    className="primary"
                  />
                </li>
              </ul>
            </div>
          </div>
        </form>
         {/* 
        <Link to="/Elements"> Check out Elements page</Link>
         */}
      </div>
      <Footer />
    </section>

    <Scrollspy
      items={sections.map(s => s.id)}
      currentClassName="active"
      offset={50}
      componentTag={'div'}
    >
      {sections.map(s => {
        return (
          <div
            key={s.id}
            className="main-bg"
            id={`${s.id}-bg`}
            style={{ backgroundImage: `url(${overLayPic}), url(${s.bg})` }}
          />
        );
      })}
    </Scrollspy>
  </Layout>
);

export default IndexPage;
